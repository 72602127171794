<template>
  <Modal class="modalUserMagnament" :showMask="true" @onCancel="close">
    <template v-slot:header>
      <div class="modalUserMagnament__header">
        <h2 class="modalUserMagnament__title">{{ title + " usuario" }}</h2>
        <button class="modalUserMagnament__close" @click="close">
          <iconic class="modalUserMagnament__icon" name="close" />
        </button>
      </div>
    </template>
    <template v-slot:body>
      <div class="modalUserMagnament__body">
        <div class="modalUserMagnament__contentOptions">
          <div class="modalUserMagnament__contentOptions" v-for="option in getOptions" :key="option.index">
            <button :class="['modalUserMagnament__buttonOption', option.index === optionSelected && 'modalUserMagnament__buttonOption--selected']" @click="setOption(option.index)">
              <span>{{ option.name }}</span>
              <iconic
                :class="`modalUserMagnament__icon modalUserMagnament__icon--${hasRequiredFiled[option.component] ? 'aprove' : 'closeBold'}`"
                :name="hasRequiredFiled[option.component] ? 'aprove' : 'closeBold'"
              />
            </button>
            <component :ref="option.component" :is="currentComponent" v-if="option.component === currentComponent && screenWidth <= 768"></component>
          </div>
        </div>
        <div class="modalUserMagnament__contentData" v-if="screenWidth > 768">
          <component :ref="currentComponent" :is="currentComponent"></component>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="modalUserMagnament__fotter">
        <button class="modalUserMagnament__buttonAction modalUserMagnament__buttonAction--cancel" @click="close">Cancelar</button>
        <button class="modalUserMagnament__buttonAction" :disabled="!hasAllRequiredFiled || !valuesChanged.length" @click="updateUser" v-if="hasAllRequiredFiled && valuesChanged.length && isEditUser">
          {{ title }}
        </button>
        <button class="modalUserMagnament__buttonAction" :disabled="!hasAllRequiredFiled || !valuesChanged.length" @click="createUser" v-else-if="optionSelected === getOptions.length">
          {{ title }}
        </button>
        <button class="modalUserMagnament__buttonAction" :disabled="!hasRequiredFiled[currentComponent]" @click="setOption(optionSelected + 1)" v-else>
          Siguiente
        </button>
      </div>
    </template>
  </Modal>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import Swal from "sweetalert2";
  export default {
    components: {
      Modal: () => import("@/components/modals/Modal.vue"),
      TypeUser: () => import("@/components/user/modalMagnamentUser/TypeUser.vue"),
      ApplicationsUser: () => import("@/components/user/modalMagnamentUser/ApplicationsUser.vue"),
      PersonalData: () => import("@/components/user/modalMagnamentUser/PersonalData.vue"),
      InformationRoleModel: () => import("@/components/user/modalMagnamentUser/InformationRoleModel.vue"),
      UserCredentials: () => import("@/components/user/modalMagnamentUser/UserCredentials.vue"),
      UserPlatforms: () => import("@/components/user/modalMagnamentUser/UserPlatforms.vue"),
      UserLocation: () => import("@/components/user/modalMagnamentUser/UserLocation.vue"),
      UserPolicies: () => import("@/components/user/modalMagnamentUser/UserPolicies.vue"),
    },
    data() {
      return {
        title: "Crear",
        typeOptions: {
          TypeUser: "Tipo de usuario",
          PersonalData: "Datos personales",
          InformationRoleModel: "Informacion modelo",
          UserLocation: "Asignación empresarial",
          ApplicationsUser: "Aplicaciones",
          UserCredentials: "Credenciales de usuario",
          UserPlatforms: "Plataformas",
          UserPolicies: "Politicas de usuario",
        },
        optionsCouldView: [1],
        cloneDataToEdit: {},
        cloneRolesApps: {},
        requiredValues: {},
      };
    },
    computed: {
      ...mapGetters("applications", {
        getAppByName: "getAppByName",
      }),
      ...mapGetters("policies", {
        policiesDb: "policiesDb",
      }),
      user() {
        return this.$store.state.user.dataUser;
      },
      isEditUser() {
        return !!this.user?._id;
      },
      roleSelected() {
        return this.user.role;
      },
      getOptions() {
        const typeOptions = structuredClone(this.typeOptions);

        if (this.isEditUser) {
          delete typeOptions.TypeUser;
          if (this.$userData.role === "coordinator") {
            delete typeOptions.PersonalData;
            delete typeOptions.ApplicationsUser;
            delete typeOptions.UserCredentials;
            delete typeOptions.UserPlatforms;
            delete typeOptions.UserPolicies;
          }
        }
        if (this.roleSelected === "superadmin") {
          delete typeOptions.UserPolicies;
        }
        if (this.roleSelected !== "model") {
          delete typeOptions.InformationRoleModel;
          delete typeOptions.UserPlatforms;
        }
        return Object.entries(typeOptions).map(([component, val], index) => ({ name: val, index: index + 1, component }));
      },
      optionSelected() {
        return this.$store.state.user.toShowInMagnament;
      },
      currentComponent() {
        return this.getOptions.find(({ index }) => index === this.optionSelected)?.component || "";
      },
      screenWidth() {
        return this.$store.state.screen.width;
      },
      hasRequiredFiled() {
        const requiredValues = this.requiredValues;

        if (this.isEditUser) {
          delete requiredValues.TypeUser;
          if (this.$userData.role === "coordinator") {
            delete requiredValues.PersonalData;
            delete requiredValues.ApplicationsUser;
            delete requiredValues.UserCredentials;
            delete requiredValues.UserPlatforms;
            delete requiredValues.UserPolicies;
          }
        }
        if (this.roleSelected !== "model") {
          delete requiredValues.InformationRoleModel;
          delete requiredValues.UserPlatforms;
        }

        const validateRequiredValues = Object.entries(requiredValues).map(([key, val]) => ({
          [key]: val.every((item) => {
            if (['roles', 'companies'].includes(item)) {
              return this.$store.state.user.dataUser[item]?.length;
            }
            return !this.$store.state.user.dataUser.hasOwnProperty(item) || this.$store.state.user.dataUser[item];
          }),
        }));

        return Object.assign({}, ...validateRequiredValues);
      },
      hasAllRequiredFiled() {
        return Object.values(this.hasRequiredFiled).every((val) => val);
      },
      valuesChanged() {
        const dataClone = this.cloneDataToEdit;
        const dataSend = this.$store.state.user.dataUser;
        const rolesAppsChanged = this.rolesAppsChanged;
        const changedRoles = this.rolesChanged;
        const policesChanged = this.policesChanged;

        const data = Object.entries(dataClone || {})
          .map(([key, val]) => {
            if (typeof val === undefined || typeof val === "object" || val === dataSend?.[key]) {
              return null;
            }
            return { [key]: dataSend?.[key] };
          })
          .filter(Boolean);

        if (Object.keys(rolesAppsChanged).length) data.push(rolesAppsChanged);
        if (Object.keys(changedRoles).length) data.push(changedRoles);
        if (Object.keys(policesChanged).length) data.push(policesChanged);
        if (Object.keys(this.companiesChanged).length) data.push(this.companiesChanged);

        return data;
      },
      rolesAppsChanged() {
        const dataSend = this.$store.state.user.dataUser.rolesApp;
        const dataClone = this.cloneDataToEdit.rolesApp;

        const keyAppsSend = Object.keys(dataSend).map((key) => key);
        const keyAppsClone = Object.keys(dataClone).map((key) => key);

        const hasChangedData = keyAppsSend.length !== keyAppsClone.length || keyAppsSend.some((key) => !keyAppsClone.includes(key) || dataClone[key][0] !== dataSend[key][0]);

        return hasChangedData ? { rolesApp: dataSend } : {};
      },
      companiesChanged(){
        const dataSend = this.$store.state.user.dataUser.companies;
        const dataClone = this.cloneDataToEdit.companies;
        const hasChangedData = dataSend.length !== dataClone.length ||  dataSend.some((key) => !dataClone.includes(key))

        return hasChangedData ? { companies: dataSend } : {};
      },
      rolesChanged() {
        const dataSend = this.$store.state.user.dataUser.roles?.[0];
        const dataClone = this.cloneDataToEdit.roles?.[0];

        return dataSend === dataClone ? {} : { roles: [dataSend] };
      },
      policesChanged() {
        const dataSend = this.$store.state.user.dataUser.polices;
        const dataClone = this.cloneDataToEdit.polices;
        const num = this.$store.state.user.numTest;

        if (JSON.stringify(dataSend?.["see-models"]?.payload) !== JSON.stringify(dataClone?.["see-models"]?.payload)) {
          return { polices: dataSend };
        }

        if (JSON.stringify(dataSend?.["Access-reservations-CMS-fields"]?.payload) !== JSON.stringify(dataClone?.["Access-reservations-CMS-fields"]?.payload)) {
          return { polices: dataSend };
        }

        const keyAppsSend = Object.keys(dataSend).map((key) => key);
        const keyAppsClone = Object.keys(dataClone).map((key) => key);

        const hasChangedData = keyAppsSend.length !== keyAppsClone.length || keyAppsSend.some((key) => !keyAppsClone.includes(key));

        return hasChangedData ? { polices: dataSend } : {};
      },
      isSatelite() {
        return this.$store.state.user.dataUser?.office === "Satelite";
      },
    },
    watch: {
      roleSelected(newVal) {
        this.$store.dispatch("user/changeRoleInDataUser", newVal);
      },
      isSatelite(val) {
        if (val) {
          const findPolice = this.policiesDb.find(({ key }) => key === "login-from-any-place");
          this.$store.state.user.dataUser.polices["login-from-any-place"] = { ...findPolice, status: true };
        } else {
          delete this.$store.state.user.dataUser.polices["login-from-any-place"];
        }
        this.$store.state.user.numTest++;
      },
    },
    methods: {
      ...mapActions("policies", {getPolicies: "getPolicies"}),
      close() {
        this.$store.state.user.toShowInMagnament = 0;
        this.$store.dispatch("user/changeRoleInDataUser", "model");
      },
      setOption(option) {
        if (!this.isEditUser) {
          this.verifyNext();

          if (!this.optionsCouldView.includes(option)) {
            return;
          }
        }

        this.$store.state.user.toShowInMagnament = option;
      },
      handleProcess(sparkCreation) {
        const { success, message } = sparkCreation;
        const template = `
        <div style="display: flex; align-items: center; gap: 10px; width: 60%; margin: 0 auto 10px;">
          <i style="color: ${success ? "#3bb54a;" : "#f7c325;"}" class="fas fa-${success ? "check" : "exclamation"}-circle"></i>
          <div style="text-align: left">${message}</div>
        </div>
      `;
        return template;
      },
      async createUser() {
        if (!this.verifyAll() || !this.hasAllRequiredFiled) {
          return;
        }
        const deleteExtraFixed = this.user.room || this.user.workShiftName ? await this.requireDeleteExtraFixed(this.user) : false;

        if (deleteExtraFixed === null) {
          return;
        }

        const { success, sparkCreation, error } = await this.$store.dispatch("user/createUser", deleteExtraFixed);
        const msgSwal = {
          text: error ? error : "Usuario creado correctamente.",
          icon: success ? "success" : "error",
          confirmButtonColor: "#bd0909",
        };
        if (!error) {
          msgSwal.html = this.handleProcess(sparkCreation);
        }
        Swal.close();
        Swal.fire(msgSwal);

        if (success) {
          this.close();
        }
      },
      async updateUser() {
        if (!this.verifyAll() || !this.hasAllRequiredFiled || !this.valuesChanged.length) {
          return;
        }

        const body = Object.assign({}, ...this.valuesChanged);
        body._id = this.user._id;
        body.host = this.getAppByName("reservations").api || "";
        body.deleteExtraFixed = body.room || (body.workShiftName && this.user.role === "model") ? await this.requireDeleteExtraFixed(body) : false;

        if (body.deleteExtraFixed === null) {
          return;
        }

        const { success, userEdited, error } = await this.$store.dispatch("user/updateUser", body);

        const msgSwal = {
          text: error ? error : "Usuario editado correctamente.",
          icon: success ? "success" : "error",
          confirmButtonColor: "#bd0909",
        };

        Swal.close();
        Swal.fire(msgSwal);

        if (error && error === "Habitación ocupada") {
          this.$store.dispatch("users/fetchUsers");
        }
        if (success) {
          this.$store.commit("users/update", userEdited);
          this.close();
        }
      },
      async requireDeleteExtraFixed(body) {
        const extraFixedsFinded = await this.verifyExtraFixed(body._id, body.room, body.workShiftName);

        if (!extraFixedsFinded.length) {
          return false;
        }

        const isPlural = extraFixedsFinded.length > 1;

        let usersWithExtraFixed = "";
        extraFixedsFinded.forEach((user) => (usersWithExtraFixed += `, ${user.artisticName || user.user}`));

        const { isConfirmed } = await this.$alerts.dataSendConfirm({
          message: `${isPlural ? "Los" : "El"} usuario${isPlural ? "s" : ""}${usersWithExtraFixed} tiene${isPlural ? "n" : ""} reservas extras fijas en esta habitación y turno, ¿quieres cancelarlas?`,
          buttonTextYes: "Si, Cancelar",
          buttonTextNo: "No",
        });

        return isConfirmed || null;
      },
      async verifyExtraFixed(_id, room, workShiftName) {
        try {
          let query = "";

          if (_id) query += `userId=${_id}&`;
          if (room) query += `room=${room}&`;
          if (workShiftName) query += `workShift=${workShiftName}&`;

          const { data } = await this.$axios.get(`/roomAvailable?${query}`);
          const { extraFixeds, error } = data;

          if (error) {
            throw new Error(error);
          }

          return extraFixeds;
        } catch (error) {
          console.log(error);
          return [];
        }
      },
      verifyNext() {
        const response = this.$refs[this.currentComponent]?.verifyNext?.() ?? true;
        const optionSelected = this.optionSelected;

        if (response) {
          this.optionsCouldView[optionSelected] = optionSelected + 1;
        } else {
          this.optionsCouldView.splice(optionSelected);
        }
      },
      verifyAll() {
        const response = this.getOptions.every(({ index, component }) => {
          const isVeify = this.$refs[component]?.verifyNext?.() ?? true;

          if (!isVeify) {
            this.$store.state.user.toShowInMagnament = index;
          }

          return isVeify;
        });
        return response;
      },
    },
    created() {
      this.cloneDataToEdit = structuredClone(this.$store.state.user.dataUser);
      const requiredValues = structuredClone(this.$store.state.user.requiredValues);

      if (this.isEditUser) {
        requiredValues["PersonalData"].pop();
        requiredValues["UserCredentials"].pop();
        requiredValues["UserCredentials"].pop();

        this.title = "Editar";
      }

      this.requiredValues = requiredValues;
    },
    async mounted() {
      await this.getPolicies();
    },
  };
</script>

<style lang="scss">
  .modalUserMagnament {
    .modal-g__container {
      width: 100%;
      max-width: 1440px;
      padding: 0;
      gap: $mpadding;
    }
    &__header {
      @include Flex(row, space-between);
      width: 100%;
      padding: $mpadding;
      border-bottom: 1px solid #c4c4c4;
    }
    &__title {
      font-weight: lighter;
    }
    &__close {
      @include Flex(row);
      height: 35px;
      width: 35px;
      color: $primary-color;
      border: none;
      border-radius: 50%;
      background: none;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:hover {
        background: #bd09092e;
      }
    }
    &__body {
      @include Flex(row, space-between, flex-start);
      width: 100%;
      padding: 0 $mpadding;
      gap: $mpadding * 1.5;
      overflow: auto;
      scrollbar-width: thin;
      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-thumb {
        border-radius: 4px;
      }
    }
    &__contentData,
    &__contentOptions {
      @include Flex(column, flex-start, flex-start);
      gap: $mpadding;
      width: 100%;
    }
    &__contentData {
      height: 520px;
      padding: 0 3px;
      overflow-y: auto;
    }
    &__buttonOption {
      @include Flex(row, space-between);
      width: 100%;
      padding: $mpadding / 1.5 $mpadding;
      text-align: start;
      font-weight: 600;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 6px;
      &--selected {
        background: #f7f7f7;
      }
    }
    &__fotter {
      @include Flex(row);
      gap: $mpadding;
      padding-bottom: $mpadding;
    }
    &__buttonAction {
      width: 120px;
      height: 40px;
      font-weight: 600;
      color: white;
      background-color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 4px;
      &--cancel {
        color: $primary-color;
        background-color: white;
      }
    }
    &__icon {
      &--aprove {
        color: #209e0c;
      }
      &--closeBold {
        color: $primary-color;
      }
    }
    @media screen and (min-width: $tablet-width) {
      &__fotter {
        gap: 39px;
      }
      &__buttonAction {
        width: 160px;
      }
    }
    @media screen and (min-width: 769px) {
      &__body {
        overflow: hidden;
      }
    }
  }
</style>
